<template>
    <b-modal ref="modal-edit" v-model="computedShow" size="m" no-close-on-backdrop :title="trans('change-periodic',209)">
        <ValidationObserver ref="form" >
            <b-form @submit.prevent="onSubmit">
                
            <app-row-left-label :label="'BTW vrijgesteld'">
                <app-radio-group v-model="cArgs.default_btw_value" :options="options_default_value" validatorName="Default BTW waarde" validatorRules="required"/>
             </app-row-left-label>

            </b-form>
        </ValidationObserver>
        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="changePeriodic" :loading="loading">
                {{trans('save',175)}}
            </app-button>
        </template>
    </b-modal>
</template>

<script>
import axios from "@axios";
import { ValidationObserver } from "vee-validate";

export default {
    components: {
        ValidationObserver
    },

    props: ["show", "result", "args", "return"],

    computed: {
        computedShow: {
            get() { return this.show },
            set() { this.$emit('update:show', false) }, 
        },
        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },
        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        }
    },

    data() {
        return {
            loading: false,
            options_default_value: [
                                    {value: 0, text: this.trans('pl-no-subject-to-vat',213)},
                                    {value: 1, text: this.trans('pl-subject-to-vat',213)}
                                   ]
        };
    },

    watch: {

    },

    methods: {
        
        changePeriodic() {
            this.$refs.form.validate().then((result) => {
                if (!result) {
                    return false;
                }else{
                    this.loading = true;
                    axios
                        .post("planning/placements/changeBTW", {
                            id_placement_data_main: this.cArgs.id_placement_data_main,
                            custom_btw_value: this.cArgs.default_btw_value,
                            id_person_data_main: this.cArgs.id_person_data_main, 
                            id_client_data_main: this.cArgs.id_client_data_main,
                            id_cao_data_main: this.cArgs.id_cao_data_main,
                            id_function_data_main: this.cArgs.id_function_data_main, 
                            date_from: this.cArgs.date_from,
                        })
                        .then((res) => {
                            this.cReturn =  res.data;
                            this.cReturn.components.items_json = JSON.stringify(this.cReturn.components.items);
                            this.cReturn.calculation_concept.items_json = JSON.stringify(this.cReturn.calculation_concept.items);
                            this.cReturn.components.changed = false;
                            this.cReturn.calculation_concept.changed = false;
                            
                            this.$emit("update:show", false);
                            this.$emit("update:result", 1);
                            
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        }).finally(() => {
                            this.loading = false;
                        });
                }
            })
        },
        
        closeModal() {
            this.$emit("update:show", false);
        },
    },
};
</script>

<style scoped>
.app-local-align{
    text-align: center;
}  

.app-local-text{
    font-weight: 600 !important
}
</style>
