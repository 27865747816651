<template>
    <div>
        
        <b-card class="mb-2" v-if="profile.placement.id_placement_const_status == 1 && profile.placement.parameters != null && ((profile.placement.parameters.errors != null && profile.placement.parameters.errors.id == null) || profile.placement.parameters.errors == null)">
            <b-alert variant="light" show class="mb-0" >
                <div class="d-flex w-100 justify-content-between">
                    <div class="d-flex align-items-center">
                        <div>
                            <b-icon icon="person-lines-fill" font-scale="3"></b-icon>                   
                        </div>

                        <div class=" ml-3 w-100 align-items-center">           
                            <span class="align-middle font-weight-600 text-base">{{trans('placements-confirm-placement',213)}}</span>  <br/>                                   
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                        <!--<app-button type="primary" @click="changePlacement" v-if="(user_role < 3 || profile.can_user_edit) && profile.placement.id_placement_const_status == 2">{{trans('change', 175)}}</app-button>-->
                        <app-button type="primary" @click="recalculate" :loading="loading_recalculate" v-if="user_role < 3 || profile.can_user_edit">{{trans('placement-recalculate',222)}}</app-button>   
                        <app-button type="primary" @click="checkConfirmPlacement()" :loading="loading_confirm" v-if="user_role < 3 || profile.can_user_edit">{{trans('confirm-data',175)}}</app-button>   
                    </div>
                </div>
            </b-alert>
        </b-card>

        <b-card class="mb-2" v-if="profile.placement.id_placement_const_status == 1 && profile.placement.parameters != null && profile.placement.parameters.errors != null && profile.placement.parameters.errors.id != null">
            <b-alert variant="warning" show class="mb-0" >
                <div class="d-flex w-100 justify-content-between">
                    <div class="d-flex align-items-center">
                        <div>
                            <b-icon icon="person-lines-fill" font-scale="3" class="mr-4"></b-icon>                        
                        </div>

                        <div class="w-100 align-items-center">           
                            <span class="align-middle font-weight-600 text-base">{{trans('pl-calculation-error',213)}}</span><br/>  
                            <span class="text-small">{{trans(profile.placement.parameters.errors.message, 270)}}</span>                                   
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                        <app-button type="primary" @click="recalculate" :loading="loading_recalculate" v-if="user_role < 3 || profile.can_user_edit">{{trans('placement-recalculate',222)}}</app-button>                     
                    </div>
                </div>
            </b-alert>
        </b-card>
        
        <!--{{profile.periods}}
        {{cNewestPlacement}}-->
        
        <b-card class="mb-2" v-if="profile.placement.id_placement_const_status == 2 && cConceptPlacementCount == 0">
            <b-alert variant="light" show class="mb-0" >
                <div class="d-flex w-100 justify-content-between">
                    <div class="d-flex align-items-center">
                        <div>
                            <b-icon icon="person-lines-fill" font-scale="3"></b-icon>                   
                        </div>
                        <div class=" ml-3 w-100 align-items-center">           
                            <span class="align-middle font-weight-600 text-base">{{trans('pl-action-change-placement',213)}}</span>  <br/>                                   
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                        <app-button type="primary" @click="changePlacement()" v-if="user_role < 3 || profile.can_user_edit">{{trans('change',175)}}</app-button>
                    </div>
                </div>
            </b-alert>
        </b-card>

        <placement-profile-change v-if="mPlacementChange.show" :show.sync="mPlacementChange.show" :result.sync="mPlacementChange.result" :args.sync="mPlacementChange.args" :return.sync="cProfile"/>   
        <placement-profile-confirm-concept v-if="mPlacementConfirm.show" :show.sync="mPlacementConfirm.show" :result.sync="mPlacementConfirm.result" :args.sync="mPlacementConfirm.args" :return.sync="zzp_documents" />

    </div>
</template>
<script>

import moment from "moment";
import axios from "@axios";
import PlacementProfileChange from "./PlacementProfileChange.vue";
import PlacementProfileConfirmConcept from "./PlacementProfileConfirmConcept.vue";

export default {
    
    props:["profile", "validationCalculationElements"],

    components: {        
        PlacementProfileChange,
        PlacementProfileConfirmConcept
    },

    mounted(){
        
    },

    computed: {
        cProfile: {
            get() { return this.profile },
            set(value) { this.$emit('update:profile', value) }
        }, 

        cConceptPlacementCount:{

            get(){
                return this.profile.periods.filter(function (value) {
                            return value.id_placement_const_status == 1
                        }).map(element => {return element}).length;
            }

        },

        cValidationCalculationElements:{
            get() { return this.validationCalculationElements },
            set(value) { this.$emit('update:validationCalculationElements', value) }
        }
    },

    watch: {
      "mPlacementConfirm.result": {
            handler: function(val) {
                if (this.mPlacementConfirm.result == 1) {
                    this.mPlacementConfirm.result = 0;
                    this.confirmPlacement();     
                }                
            },
        },
    },

    data(){
        return{
            loading_recalculate: false,
            loading_confirm: false,
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
            zzp_documents: {person_document: 0, client_document: 0, model_b_document: 0},
            mPlacementChange: { show: false, args: {id_placement_data_main: null, date_from: null, date_to: null, periods: null}, result: 0, return: null }, 
            mPlacementConfirm: {show: false, args: {person_document: 0, client_document: 0, model_b_document: 0}, result: 0, return: null}
        }
    },

    methods: {
        
        recalculate() {
           this.loading_recalculate = true;
            axios
                        .post("planning/placements/recalculate", {
                            id_person_data_main: this.profile.person.id_person_data_main, 
                            id_client_data_main: this.profile.client.id_client_data_main,
                            id_cao_data_main: this.profile.placement.id_cao_data_main,
                            id_function_data_main: this.profile.placement.id_function_data_main, 
                            date_from: this.profile.placement.date_from,
                        })
                        .then((res) => {
                            this.cProfile = res.data;                    
                            this.cProfile.components.items_json = JSON.stringify(this.cProfile.components.items);
                            this.cProfile.components.changed = false;
                            this.cProfile.calculation_concept.items_json = JSON.stringify(this.cProfile.calculation_concept.items);
                            this.cProfile.calculation_concept.changed = false;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        }).finally(() => {
                            this.loading_recalculate = false;
                        });
        },

        checkConfirmPlacement() {
            this.$root.$emit("validPlacementComponents");
            
            setTimeout(() => {
            
                if(this.validationCalculationElements){
                    if(this.profile.person.type == 2 && this.profile.periods.filter(function (item) {return item.id_placement_const_status == 2}).map(element => {return element}).length > 0){//ZZP and confirm edited placement
                
                        this.mPlacementConfirm.args.person_document = 0;
                        this.mPlacementConfirm.args.client_document = 0;
                        this.mPlacementConfirm.args.model_b_document = 0;
                        this.mPlacementConfirm.show = true;
                        
                    }else{
                        this.confirmPlacement();
                    }
                }

            }, 250);

        },

        confirmPlacement(){
            
            this.loading_confirm = true;
            let first_placement = (this.profile.periods.filter(function (item) {return item.id_placement_const_status == 2}).map(element => {return element}).length == 0 ? 1 : 0);

            axios
                .post("planning/placements/confirmPlacement", {
                    id_placement_data_main: this.profile.placement.id_placement_data_main,
                    id_person_data_main: this.profile.person.id_person_data_main, 
                    id_client_data_main: this.profile.client.id_client_data_main,
                    first_placement: first_placement,
                    zzp_documents: JSON.stringify(this.zzp_documents)
                })
                .then((res) => {
                    this.cProfile = res.data;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                }).finally(() => {
                    this.loading_confirm = false;
                });
        },

        changePlacement(){
            
            this.mPlacementChange.args.id_placement_data_main = this.profile.placement.id_placement_data_main;
            this.mPlacementChange.args.periods = this.profile.periods;
            this.mPlacementChange.args.date_from = moment(this.profile.placement.placement_date_from_format, 'DD-MM-YYYY').add(1, "d").format('DD-MM-YYYY');
            this.mPlacementChange.args.id_person_data_main = this.profile.person.id_person_data_main;
            this.mPlacementChange.args.id_client_data_main = this.profile.client.id_client_data_main;
            this.mPlacementChange.show = true;
            
        }
    }

};
</script>

