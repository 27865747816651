<template>
    <div class="my-2">

        <b-row class="app-card-header">
            <b-col start>
                <h4 class="card-title">
                    {{trans('sh-worked-hours',215)}}    
                </h4>
            </b-col>
        </b-row>
        <b-row class="ml-1 mr-1 pb-2 app-local-row-header-font">
            <b-col class="pl-1">
                {{trans('sh-description',215)}}
            </b-col>
            <b-col end cols="auto" class="app-width-min-100">
                {{trans('sh-compensation',215)}}
            </b-col>
            <b-col end cols="auto" class="app-width-min-100">
                {{trans('sh-duration',215)}}  
            </b-col>
        </b-row>

        <b-list-group flush class="app-local-bottom-line">
                            
            <b-list-group-item class="p-2 ml-2 mr-2" v-for="item in hours.hours" :key="item.index">
                <b-row align-v="center" class="pl-2">
                    <b-col class="p-0">
                        <span class="pl-1 app-local-row-font" v-if="item.type == 1"> {{ item.time_from }} - {{ item.time_to}} </span>
                        <span class="pl-1 app-local-row-font" v-if="item.type == 2"> {{trans('sh-pause', 215)}} </span>
                    </b-col>
                    <b-col end cols="auto" class="app-width-min-100">
                        <span class="pl-1 app-local-row-font"> {{ parseInt(item.percent)+100 }}% </span>
                    </b-col>
                    <b-col end cols="auto" class="app-width-min-100">
                        <span class="pl-1 app-local-row-font"> {{ (''+item.hours).replace('.', ',') }} {{trans('sh-hour', 215).toLowerCase()}} </span>
                    </b-col>
                </b-row>
            </b-list-group-item>

            <b-list-group-item class="p-2 ml-2 mr-2" v-if="hours.hours.length > 0">
                <b-row align-v="center" class="pl-2">
                    <b-col class="p-0">
                        <h4 class="pl-1 card-title mb-2 mt-2"> {{trans('sh-total-work-hours', 215)}} </h4>
                    </b-col>
                    <b-col end cols="auto" class="app-width-min-100">
                    </b-col>
                    <b-col end cols="auto" class="app-width-min-100">
                        <h4 class="pl-1 card-title mb-2 mt-2"> {{(''+cTotalHours).replace('.', ',')}} {{trans('sh-hour', 215).toLowerCase()}} </h4>
                    </b-col>
                </b-row>
            </b-list-group-item>

        </b-list-group>

            

         <b-row class="app-card-header mt-2">
            <b-col start>
                <h4 class="card-title">
                    {{trans('declaration',3)}} 
                </h4>
            </b-col>
        </b-row>

        <b-row class="ml-1 mr-1 pb-2 app-local-row-header-font">
            <b-col class="pl-1">
                {{trans('sh-description',215)}}
            </b-col>
            <b-col end cols="auto" class="app-width-min-100">
                {{trans('sh-number-of-km',215)}} 
            </b-col>
            <b-col end cols="auto" class="app-width-min-100">
                {{trans('sh-amount',215)}}
            </b-col>
        </b-row>

        <b-list-group flush>
                            
            <b-list-group-item class="p-2 ml-2 mr-2" v-for="item in hours.declarations" :key="item.index">
                <b-row align-v="center" class="pl-2">
                    <b-col class="p-0">
                        <span class="pl-1 app-local-row-font"> {{ item.name }} </span>
                    </b-col>
                    <b-col end cols="auto" class="app-width-min-100">
                        <span class="pl-1 app-local-row-font">{{item.distance}}</span>
                    </b-col>
                    <b-col end cols="auto" class="app-width-min-100">
                        <span class="pl-1 app-local-row-font"> €{{ (((item.distance != null ? item.distance : 1) * parseFloat(item.person_value)).toFixed(2)).replace('.', ',') }} </span>

                        <b-dropdown  variant="link" no-caret right boundary="window" :class="{'app-local-padding': item.id_placement_const_component == 6}" v-if="item.id_placement_const_component == 6" >
                            <template #button-content>
                                <b-icon icon="three-dots-vertical" class="text-body" style="margin-right:-15px; margin-top: -4px"></b-icon>
                            </template>
                                            
                            <b-dropdown-item @click="openDocument(item)">{{trans('show-document',3)}}</b-dropdown-item>                                                
                                                                           
                        </b-dropdown>
                    </b-col>
                </b-row>
            </b-list-group-item>
                            

            <b-list-group-item class="p-2 ml-2 mr-2" v-if="hours.declarations.length > 0">
                <b-row align-v="center" class="pl-2">
                    <b-col class="p-0">
                        <h4 class="pl-1 card-title mb-2 mt-2"> {{trans('sh-total-declaration',215)}} </h4>
                    </b-col>
                    <b-col end cols="auto" class="app-width-min-100">
                    </b-col>
                    <b-col end cols="auto" class="app-width-min-100">
                        <h4 class="pl-1 card-title mb-2 mt-2"> €{{(''+cTotalDeclaratie).replace('.', ',')}}  </h4>
                    </b-col>
                </b-row>
            </b-list-group-item>

        </b-list-group>


    </div>
</template>

<script>

import axios from "@axios";
import AppAlerts from '@core/scripts/AppAlerts';

export default {
    components: { 
       
    },

    computed: {
        cTotalHours : {
            get() { 
                    if(this.hours.hours.length > 0){

                        let hours = 0;
                        this.hours.hours.forEach(item => {
                            hours = hours + item.hours;
                        });

                        return hours;
                    }else{
                        return 0;
                    }
                },
        },

        cTotalDeclaratie : {
            get() { 
                    if(this.hours.declarations.length > 0){

                        let value = 0;
                        this.hours.declarations.forEach(item => {

                            if(item.distance != null){
                                value = value + (item.distance * (parseFloat(item.person_value)).toFixed(2));
                            }else{
                                 value = value + parseFloat(item.person_value);
                            }
                        });

                        return value.toFixed(2);
                    }else{
                        return 0;
                    }
                },
        },

        cIsParking: {
            get(){
                var res = this.hours.declarations.filter(item => {
                    return item.id_placement_const_component == 6;
                });

                return res.length > 0;
            }
        }

    },

    data() {
        return {
           hours: JSON.parse(this.itemData.hours),
           alert_class: new AppAlerts(),
        };
    },

    props:["itemData"],

    mounted() {
       
    },

    methods: {
        openDocument(data){
            
            
            axios
                .post("planning/placements/downloadFile", {
                    id_document_data_main: data.id_document_data_main,
                    id_hour_data_main: this.itemData.id,
                }, 
                {
                    responseType: 'blob', 
                    crossDomain: true,
                })
                .then((res) => {
                    const url = window.URL.createObjectURL(new Blob([res.data], {type : data.mine}));
                    const link = document.createElement('a');
                    link.href = url;
                    link.target= "_blank";
                     data.mine == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                     data.mine == 'application/msword' ||
                     data.mine == 'text/plain'
                                     ? link.setAttribute('download', null):link.setAttribute('open', null); 
                    link.setAttribute('open', null); 
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((error) => {
                    console.error(error);
                    this.alert_class.openAlertWarning(this.trans('file-not-found',180)).then(res => {})
                })
                .finally(() => {
                   
                });

        },

    }
};
</script>

<style scoped>

.app-local-row-font {
    color: #174066;
    font-weight: 300;
    font-size: 14px;
}

.app-local-row-header-font {
    font-weight: 600;
    color: #1f627f;
    font-size: 14px;
    text-transform: none;
    letter-spacing: 1px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.05);
}

.app-local-bottom-line {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.app-local-text{
    color: #7f7f7f !important;
}

.app-local-text:hover{
    color: black !important;
    opacity: 0.8;
}

.app-local-text:focus{
    color: black !important;
    opacity: 0.8;
}

.app-local-margin{
    margin-left: -5px !important;
}



</style>
<style scoped>
.app-local-padding .btn{
    padding: 0px !important;
    padding-left:5px !important;
}
</style>
