<template>
    <div>
        <b-overlay :show="loading" :opacity="0.5">
            <b-navbar sticky class="app-navbar-profile">
                <div class="app-navbar-header">
                    <b-row>
                        <b-col cols="8" class="app-navbar-profile-margin-4"></b-col>
                        <b-col end class="app-navbar-profile-margin-30">
                        <div class="d-flex justify-content-end align-items-center">
                            <div class="font-weight-800 mr-4 mt-1"> {{trans('core-placement-profile', 271)}} </div>
                                <b-dropdown variant="link" right>
                                    <template #button-content>
                                        <b-icon icon="gear-fill" style="width: 17px; height: 17px;"></b-icon>
                                    </template>
                                    <b-dropdown-item >{{trans('history',182)}}</b-dropdown-item>
                                </b-dropdown>
                                
                            </div>
                        </b-col>
                    </b-row>
                    <b-row class="align-items-center">
                        <b-col>
                            <placements-profile-status-bar :profile.sync="profile" :loading="loading"/>
                        </b-col>
                    </b-row>
                </div>
            </b-navbar>
            <div v-if="profile != null">
                <b-row>
                    <b-col md="12" lg="8" class="pr-lg-2">
                        <placement-profile-action-points-component :profile.sync="profile" :validationCalculationElements.sync="validation_calculation_elements" />
                        <placement-profile-calculation-elements-component class="mb-2" :profile.sync="profile" :validationCalculationElements.sync="validation_calculation_elements" />
                        <placement-profile-activities-description-component class="mb-2" :profile.sync="profile" />  
                        
                        <app-collapse accordion type="margin">
                            <b-card no-body class="app-card-profile">
                            <app-collapse-item style="box-shadow:none !important;" :title="trans('documents',3)" :id="'documents'">
                                <placement-profile-documents-component :profile.sync="profile" />
                            </app-collapse-item>
                        </b-card>

                            <b-card no-body class="app-card-profile">
                            <app-collapse-item style="box-shadow:none !important;" :title="trans('sh-hours',215)" :id="'hours'">
                                <placement-profile-hours-component :profile.sync="profile" />
                            </app-collapse-item>
                            </b-card>

                        </app-collapse>

                    </b-col>

                    <b-col md="12" lg="4" class="mt-3 mt-lg-0 pl-lg-2">
                        <placement-profile-parameters-component :profile.sync="profile" />
                        <placement-profile-person-component class="mt-2" :profile.sync="profile" />
                        <placement-profile-client-component class="mt-2" :profile.sync="profile" />  
                        <notes-component class="mt-2" :profile.sync="profile"/>                  
                    </b-col>
                </b-row>  
            </div>
            <template #overlay>
                    <div></div>
            </template>
        </b-overlay>
    </div>
</template>
<script>

import axios from "@axios";
import AppCollapse from "@core/components/AppCollapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/AppCollapse/AppCollapseItem.vue";
import PlacementProfileActionPointsComponent from './Components/PlacementProfileActionPointsComponent.vue';
import PlacementProfileActivitiesDescriptionComponent from './Components/PlacementProfileActivitiesDescriptionComponent.vue';
import PlacementProfileCalculationElementsComponent from './Components/PlacementProfileCalculationElementsComponent.vue';
import PlacementProfileClientComponent from './Components/PlacementProfileClientComponent.vue';
import PlacementProfileParametersComponent from './Components/PlacementProfileParametersComponent.vue';
import PlacementProfilePersonComponent from './Components/PlacementProfilePersonComponent.vue';
import PlacementProfileDocumentsComponent from './Components/PlacementProfileDocumentsComponent.vue';
import PlacementProfileHoursComponent from './Components/PlacementProfileHoursComponent.vue';

import PlacementsProfileStatusBar from './Components/PlacementsProfileStatusBarComponent.vue';
import NotesComponent from '@app/Notes/NotesComponent.vue';

export default {

    components: {
        AppCollapse,
        AppCollapseItem,
        PlacementsProfileStatusBar,
        PlacementProfilePersonComponent,
        PlacementProfileClientComponent,
        PlacementProfileParametersComponent,
        PlacementProfileActionPointsComponent,
        PlacementProfileCalculationElementsComponent,
        PlacementProfileActivitiesDescriptionComponent,
        PlacementProfileDocumentsComponent,
        PlacementProfileHoursComponent,
        NotesComponent,
    },

    props: [],

    created() {
        this.getData();
    },

    watch:{ 
        "profile.placement.current_placement": {
            handler: function(newVal, oldVal){
                if(oldVal != undefined){
                    if(newVal != oldVal){
                       this.getData(newVal); 
                    }
                }   
            },
            deep: true
        }
    },

    data(){
        return{
            id_placement_data_main: this.$route.params.id_placement_data_main,
            profile: null,
            loading: false,
            validation_calculation_elements: false
        }
    },

    methods:{
         getData(id=null) {

            if(id != null){
                this.loading = true;
            }

            axios
                .get("planning/placements/getProfile/" + (id != null ? id : this.id_placement_data_main))
                .then((res) => {                  
                    this.profile = res.data; 
                    this.profile.components.items_json = JSON.stringify(this.profile.components.items);
                    this.profile.calculation_concept.items_json = JSON.stringify(this.profile.calculation_concept.items);
                    this.loading = false;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                });
            },
    }
    
}
</script>
<style>

</style>