<template>
    <b-card no-body class="app-card-profile"> 
        <b-row>
            <b-col start>
                <div class="app-card-profile-title" :class="profile.placement.function_description == null ? 'ml-2 mt-2 mb-1' : 'mb-3'">
                    {{trans('placement-activities-description',213)}}
                </div>
            </b-col>              
        </b-row>
        <b-row class="app-component-icon-edit">
            <b-dropdown variant="link"  no-caret right>
                <template #button-content>
                    <b-icon icon="three-dots-vertical" class="text-body"></b-icon>
                </template>
                <b-dropdown-item :to="{ name: 'people-person-profile', params: { id_person_data_main: profile.person.id_person_data_main }}">{{trans('to-flexworker',175)}}</b-dropdown-item>
                <b-dropdown-item @click="changeDescription()" v-if="user_role < 3 || profile.can_user_edit">{{trans('change-description',3)}}</b-dropdown-item>
            </b-dropdown>
        </b-row> 
        <b-row class="mb-2" v-if="profile.placement.function_description != null">
            <b-col>
                <div class="ql-editor col-form-value app-local-ql-display" v-html="profile.placement.function_description">
                </div>
            </b-col>
        </b-row>
        <placement-profile-activities-description-change v-if="mPlacementDescriptionEdit.show" :show.sync="mPlacementDescriptionEdit.show" :result.sync="mPlacementDescriptionEdit.result" :args="mPlacementDescriptionEdit.args" :return.sync="cProfile" />
    </b-card>
    
</template>
<script>

import moment from "moment";
import PlacementProfileActivitiesDescriptionChange from './PlacementProfileActivitiesDescriptionChange.vue'

export default {
    
    components:{
        PlacementProfileActivitiesDescriptionChange
    },

    props:["profile"],

    mounted(){
        
    },

    computed: {
        cProfile: {
            get() { return this.profile },
            set(value) { this.$emit('update:profile', value) }
        }, 
    },

    data(){
        return{
            mPlacementDescriptionEdit: { show: false, args: {
                    placement_data:{
                        id: null,
                        description: null
                    },
                }, result: 0, return: null },
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,

        }
    },

    methods: {
        openClientProfile() {

        },

        openPersonProfile() {

        }, 

        changeDescription() {
            this.mPlacementDescriptionEdit.args.placement_data.id = this.cProfile.placement.id_placement_data_main;
            this.mPlacementDescriptionEdit.args.placement_data.description = this.cProfile.placement.function_description ? this.cProfile.placement.function_description : '';

            this.mPlacementDescriptionEdit.show = true;
            
        },
    }

};
</script>
<style scoped>
     .app-local-ql-display{
        border: none !important;
     }
</style>
