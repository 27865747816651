<template>
    <b-card no-body class="app-card-profile"> 
        <b-row>
            <b-col start>
                <div class="app-card-profile-title mb-3">
                    {{trans('placement-calculation-elements',213)}}
                </div>
            </b-col>              
        </b-row>

        <b-row class="ml-1 mr-1 pb-2 app-local-row-header-font">
            <b-col class="pl-1">
                    {{trans('full-name',3)}}
            </b-col>
        
            <b-col end cols="auto" class="app-width-min-220 app-width-max-220">
                    {{profile.person.type == 1 ? trans('flexworker',182) : trans('pl-zzp-rate',213)}}
            </b-col>
            <b-col end cols="auto" class="app-ellips" v-bind:class="{'app-width-min-220 app-width-max-220': profile.person.type == 1, 'app-width-min-300 app-width-max-300': profile.person.type == 2}">
                <span v-overflow-tooltip>{{profile.person.type == 1 ? trans('core-client',3) : trans('pl-contractually-agreed-rates-with-customer',213)}}</span>
            </b-col>
            <b-col end cols="auto" class="app-width-min-75">
                    
            </b-col>
        </b-row>
        
        <ValidationObserver ref="form">
            <b-list-group flush class="app-local-bottom-line" v-if="cProfile.calculation_concept.items != null && cProfile.calculation_concept.items.length > 0">
                
                <b-list-group-item  class="p-2 ml-2 mr-2" v-for="item in cProfile.calculation_concept.items" :key="item.index">
                    <b-row class="pl-2">
                        <b-col class="p-0 d-flex align-items-center">
                                    <span class="pl-1 app-local-row-font"> {{ item.name }} </span>
                        </b-col>
                        <b-col end cols="auto" class="app-width-min-220 app-width-max-220">
                            <div class="d-flex">
                                <div class="app-width-min-160 app-width-max-160">
                                    <app-input v-model="item.person_value" :disabled="item.person_change == 0 || profile.placement.id_placement_const_status == 2" type="decimal_02" :validatorRules="item.person_change == 1 ? 'required' : ''" :validatorCustomMessage="{'required': 'Niet alle tarieven zijn gevuld.'}"/>
                                </div>
                                
                                <b-icon v-if="profile.placement.id_placement_const_status == 1 && profile.person.type == 2 && (item.person_value == null || item.person_value == '')" variant="danger" class="ml-2 app-local-icon-margin" end icon="exclamation-circle-fill" data-toggle="tooltip" data-placement="top" :title="trans('pl-no-rate-set-for-self-employed',213)"></b-icon>
                                <b-icon v-if="profile.placement.id_placement_const_status == 1 && profile.person.type == 2 && item.person_value != null && item.client_value_to != null && parseFloat(item.person_value.toString().replace(',', '.')) > parseFloat(item.client_value_to.toString().replace(',', '.'))" variant="danger" class="ml-2 app-local-icon-margin" end icon="exclamation-circle-fill" data-toggle="tooltip" data-placement="top" :title="trans('pl-zzp-rate-is-higher-than-agreed',213)"></b-icon>
                                <b-icon v-if="profile.placement.id_placement_const_status == 1 && profile.person.type == 2 && item.person_value != null && item.client_value_from != null && parseFloat(item.person_value.toString().replace(',', '.')) < parseFloat(item.client_value_from.toString().replace(',', '.'))" variant="danger" class="ml-2 app-local-icon-margin" end icon="exclamation-circle-fill" data-toggle="tooltip" data-placement="top" :title="trans('pl-zzp-rate-is-lower-than-agreed',213)"></b-icon>
                                
                            </div>

                        </b-col>
                        
                        <b-col end cols="auto" class="app-ellips d-flex align-items-center" v-bind:class="{'app-width-min-220 app-width-max-220': profile.person.type == 1, 'app-width-min-300 app-width-max-300': profile.person.type == 2}">
                            <app-input v-if="profile.person.type == 1" v-model="item.client_value" :disabled="item.client_change == 0 || profile.placement.id_placement_const_status == 2" type="decimal_02"/>
                            
                            <span v-if="profile.person.type == 2 && item.client_value_from != null && item.client_value_to != null" class="pl-1 app-local-row-font"> {{trans('pl-rate-range-between-client-value', 213, {client_value_from : item.client_value_from, client_value_to : item.client_value_to})}}</span>
                            <span v-if="profile.person.type == 2 && item.client_value_from != null && item.client_value_to == null" class="pl-1 app-local-row-font"> {{trans('pl-fixed-rate-of-value', 213, {client_value_from : item.client_value_from})}} </span>
                            <span v-if="profile.person.type == 2 && item.client_value_from == null && item.client_value_to == null" class="pl-1 app-local-row-font"> {{trans('pl-no-rates',213)}} </span>

                        </b-col>
                            <b-col end cols="auto" class="app-width-min-75">
                            <span class="pl-1 app-local-row-font">  </span>
                        </b-col>
                    </b-row>
                    
                </b-list-group-item>
            </b-list-group>
        
            <div v-else class="d-flex justify-content-center align-items-center app-table-no-records mb-3 mt-3">
                {{trans('no-records-to-display',180)}}
            </div>

        
            <div v-if="cProfile.components.items != null && cProfile.components.items.length > 0">
                <b-row class="mt-4 ml-1 mr-1 pb-2 app-local-row-header-font">
                    <b-col class="pl-1">
                        {{trans('placement-wage-component',213)}}
                    </b-col>
                    <b-col end cols="auto" class="app-width-min-220 app-width-max-220">
                        {{trans('flexworker',182)}}
                    </b-col>
                    <b-col end cols="auto" class="app-width-min-220 app-width-max-220">
                        {{trans('organization',169)}}  
                    </b-col>
                    <b-col end cols="auto" class="app-width-min-75">
                        
                    </b-col>
                </b-row>
                <b-list-group flush class="app-local-bottom-line" >
                    <b-list-group-item class="p-2 ml-2 mr-2" v-for="item in cProfile.components.items" :key="item.index">
                        <b-row class="pl-2">
                            <b-col class="p-0 d-flex align-items-center">
                                    <span class="pl-1 app-local-row-font"> {{ item.name }} </span>
                            </b-col>
                            <b-col end cols="auto" class="app-width-min-220 app-width-max-220">
                                
                                <!--COMPONENT MAX aantal KM-->
                                <app-input v-if="item.id_placement_const_component == 9" v-model="item.person_value" :disabled="item.person_change == 0 || profile.placement.id_placement_const_status == 2" type="integer_03" :validatorRules="('app-min-value:' + item.client_value)" :validatorCustomMessage="{ 'app-min-value': 'Het maximale aantal kilometers is gelijk of hoger dan aan de klant kant.' }"/>
                                <!--ELSE-->
                                <app-input v-else v-model="item.person_value" :disabled="item.person_change == 0 || profile.placement.id_placement_const_status == 2" type="decimal_02"/>
                                
                            </b-col>
                            <b-col end cols="auto" class="app-width-min-220 app-width-max-220">
                                
                                <!--COMPONENT MAX aantal KM-->
                                <app-input v-if="item.id_placement_const_component == 9" v-model="item.client_value" :disabled="item.client_change == 0 || profile.placement.id_placement_const_status == 2" type="integer_03" validatorRules="required" validatorName="max_km" :validatorCustomMessage="{'required': 'Geen maximale aantal kilometers afgesproken met de klant.'}"/>
                                <!--ELSE-->
                                <app-input v-else v-model="item.client_value" :disabled="item.client_change == 0 || profile.placement.id_placement_const_status == 2" type="decimal_02"/>

                                <validation-provider v-if="(item.id_placement_const_component == 9)" :rules="item.client_value == null || item.client_value == '' ? 'required' : ''" #default="{ errors }" name="Organisatie max. aantal" :vid="'client_max_km'">
                                    <small class="text-danger app-input-error-ellips ml-1" v-if="errors[0] != null">{{ errors[0] }}</small>
                                </validation-provider>

                            </b-col>
                            <b-col end cols="auto" class="app-width-min-75 d-flex align-items-center">
                                <b-dropdown  variant="link" no-caret right boundary="window" v-if="(user_role < 3 || profile.can_user_edit) && profile.placement.id_placement_const_status != 2">
                                    <template #button-content>
                                        <b-icon icon="three-dots-vertical" class="text-body"></b-icon>
                                    </template>
                                    <b-dropdown-item @click="removeComponent(item)">{{trans('remove',175)}}</b-dropdown-item>                               
                                </b-dropdown>
                            </b-col>
                        </b-row>
                    </b-list-group-item>
                </b-list-group>
            </div>    

        </ValidationObserver>

        <b-row class="mt-3">
            <b-col>
                <app-button type="secondary" v-if="cProfile.placement.id_placement_const_status == 1 && (user_role < 3 || profile.can_user_edit)" @click="addComponent">{{trans('placement-add-component',222)}}</app-button>                    
            </b-col>
            <b-col cols="auto" end>
                <app-button type="secondary-grey" v-if="cProfile.placement.id_placement_const_status == 1 && (cProfile.components.changed || cProfile.calculation_concept.changed) && (user_role < 3 || profile.can_user_edit)" @click="cancelChangeComponents">{{trans('cancel',175)}}</app-button>
                <app-button type="primary" v-if="cProfile.placement.id_placement_const_status == 1 && (cProfile.components.changed || cProfile.calculation_concept.changed) && (user_role < 3 || profile.can_user_edit)" @click="changeComponents" :loading="loading_change"> {{trans('save',175)}} </app-button>
            </b-col>
        </b-row>
        <placement-profile-calculation-elements-add-component v-if="mComponentAdd.show" :show.sync="mComponentAdd.show" :args="mComponentAdd.args" :result="mComponentAdd.result" :return.sync="cProfile" />    
    </b-card>
</template>
<script>

import axios from "@axios";
import { ValidationObserver } from "vee-validate";
import PlacementProfileCalculationElementsAddComponent from './PlacementProfileCalculationElementsAddComponent.vue';
export default {
  components: { PlacementProfileCalculationElementsAddComponent,
                ValidationObserver },
    
    props:["profile", "validationCalculationElements"],

    mounted() {
        this.$root.$on('validPlacementComponents', () => {
            this.validationForm();
        });
    },

    watch:{
        "profile.components.items": {
            handler: function(val) {
                
                if(this.cProfile.components.items_json == null){
                    this.cProfile.components.items_json = JSON.stringify(this.cProfile.components.items);
                }

                if (JSON.stringify(this.cProfile.components.items) != this.cProfile.components.items_json && this.cProfile.components.items_json != null) {
                    this.cProfile.components.changed = true;
                } else {
                    this.cProfile.components.changed = false;
                }              
            },
            deep: true
        },

        "profile.calculation_concept.items": {
            handler: function(val) {
                
                if(this.cProfile.calculation_concept.items_json == null){
                    this.cProfile.calculation_concept.items_json = JSON.stringify(this.cProfile.calculation_concept.items);
                }

                if (JSON.stringify(this.cProfile.calculation_concept.items) != this.cProfile.calculation_concept.items_json && this.cProfile.calculation_concept.items_json != null) {
                    this.cProfile.calculation_concept.changed = true;
                } else {
                    this.cProfile.calculation_concept.changed = false;
                }              
            },
            deep: true
        },
    },

    computed: {
        cProfile: {
            get() { return this.profile },
            set(value) { this.$emit('update:profile', value) }
        },
       
        cAdd: {
            get() { 
                    if (this.profile.placement.id_placement_const_status == 1) {
                        return true;
                    } else {
                        return false;
                    } 
                }
        },

        cValidationCalculationElements: {
            get() { return this.validationCalculationElements },
            set(value) { this.$emit('update:validationCalculationElements', value) }
        }
    },

    data(){
        return{
            mComponentAdd: { args: { id_status_const_type: null, id_client_data_main: null}, result: 0, return: null, show: false},
            loading_change: false,
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
        }
    },

    methods: {
        
        addComponent() {
            this.mComponentAdd.show = true;
        },

        removeComponent(item) {
            this.cProfile.components.items = this.profile.components.items.filter(element => element !== item);
        },

        changeComponents() {

            this.$refs.form.validate().then((result) => {
               

                const errors = Object.entries(this.$refs.form.errors)
                            .map(([key, value]) => ({
                                key,
                                value,
                            }))
                            .filter((error) => {
                                return !error || !error.value || error.value.length > 0;
                            });

                            if (errors && errors.length > 0) {
                                errors.forEach(error => {
                                    if(error.key == 'client_max_km'){
                                
                                        this.$refs.form.setErrors({"client_max_km": 'Geen maximale aantal kilometers afgesproken met de klant.'});

                                        document.getElementById('app').scrollIntoView({
                                                                behavior: "smooth",
                                                                block: "start",
                                                            });
                                    }

                                });

                             
                            }

                if (!result) {
                    this.cValidationCalculationElements = false;
                    return false;
                }else{
                    this.cValidationCalculationElements = true;
                    this.loading_change = true;

                    this.profile.components.items.forEach(element => {
                        element.person_change = 0;
                        element.client_change = 0;
                    });

                    axios
                        .post("planning/placements/changeComponents", {
                            id_placement_data_main: this.cProfile.placement.id_placement_data_main,
                            components: JSON.stringify( this.profile.components.items),
                            calculation_concept: JSON.stringify(this.profile.calculation_concept.items),
                        })
                        .then((res) => {
                            this.loading_change = false;
                            this.cProfile.components.items_json = JSON.stringify(this.cProfile.components.items);
                            this.cProfile.components.changed = false;
                            this.cProfile.calculation_concept.items_json = JSON.stringify(this.cProfile.calculation_concept.items);
                            this.cProfile.calculation_concept.changed = false;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        });


                }
            });

        },

        validationForm(){
            
            this.$refs.form.validate().then((result) => {
               const errors = Object.entries(this.$refs.form.errors)
                           .map(([key, value]) => ({
                               key,
                               value,
                           }))
                           .filter((error) => {
                               return !error || !error.value || error.value.length > 0;
                           });

                           if (errors && errors.length > 0) {
                               errors.forEach(error => {
                                   if(error.key == 'client_max_km'){
                               
                                       this.$refs.form.setErrors({"client_max_km": 'Geen maximale aantal kilometers afgesproken met de klant.'});

                                       document.getElementById('app').scrollIntoView({
                                                               behavior: "smooth",
                                                               block: "start",
                                                           });
                                   }

                               });

                           }

               if (!result) {
                   this.cValidationCalculationElements = false;
                   return false;
               }else{
                   this.cValidationCalculationElements = true;
                   return true;
               }
           });
        },

        cancelChangeComponents(){
            this.cProfile.components.items =  JSON.parse(this.cProfile.components.items_json);
            this.cProfile.components.changed = false;
            
            this.cProfile.calculation_concept.items = JSON.parse(this.cProfile.calculation_concept.items_json);
            this.cProfile.calculation_concept.changed = false;
        },
            
    }

}
</script>
<style scoped>

.app-local-row-font {
    color: #174066;
    font-weight: 300;
    font-size: 14px;
}

.app-local-row-header-font {
    font-weight: 600;
    color: #1f627f;
    font-size: 14px;
    text-transform: none;
    letter-spacing: 1px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.05);
}

.app-local-bottom-line {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.app-local-icon-margin{
    margin-top: 0.5rem;
}
</style>

