<template>
    <div>
        <b-card no-body class="app-card-profile"> 
            <b-row>
                <b-col start>
                    <div class="app-card-profile-title mb-3">
                        {{trans('organization',169)}}
                    </div>
                </b-col>              
            </b-row>
            <b-row class="app-component-icon-edit">
                <b-dropdown variant="link"  no-caret right>
                    <template #button-content>
                        <b-icon icon="three-dots-vertical" class="text-body"></b-icon>
                    </template>
                    <b-dropdown-item :to="{ name: 'clients-profile-organization', params: { id_client_data_main: profile.client.id_client_data_main} }">{{trans('to-organization',175)}}</b-dropdown-item>
                </b-dropdown>
            </b-row> 

            <b-row v-if="profile.person.type == 1"> 
                <b-col cols="4">
                    <p class="col-form-label-ellipsis" v-overflow-tooltip >{{trans('cp-client-type',190)}}</p> 

                    <p class="col-form-label-ellipsis mt-2" v-overflow-tooltip >{{trans('placement-contract',223)}}</p>
                    <p class="col-form-label-ellipsis" v-overflow-tooltip >{{trans('date-from',182)}}</p>
                    <p class="col-form-label-ellipsis" v-overflow-tooltip >{{trans('date-to',182)}}</p>
                    <p class="col-form-label-ellipsis" v-overflow-tooltip v-if="profile.client.contract_date_from != profile.client.contract_indexation_date_from && profile.client.contract_date_to != profile.client.contract_indexation_date_to">Startdatum - indexatie</p>
                    <p class="col-form-label-ellipsis" v-overflow-tooltip v-if="profile.client.contract_date_from != profile.client.contract_indexation_date_from && profile.client.contract_date_to != profile.client.contract_indexation_date_to">Einddatum - indexatie</p>
                    
                    <p class="col-form-label-ellipsis mt-2" v-overflow-tooltip >Contract obv</p>                     
                </b-col>

                <b-col cols="8">
                    <p class="col-form-value-ellipsis" v-overflow-tooltip >{{ profile.client.client_type_name == null ? '-' : profile.client.client_type_name}}</p> 

                    <p class="col-form-value-ellipsis mt-2" v-overflow-tooltip >{{ profile.client.contract_type_name == null ? '-' : profile.client.contract_type_name }}</p>
                    <p class="col-form-value-ellipsis" v-overflow-tooltip >{{ profile.client.contract_date_from == null ? '-' : profile.client.contract_date_from }}</p>
                    <p class="col-form-value-ellipsis" v-overflow-tooltip >{{ profile.client.contract_date_to == null ? '-' : profile.client.contract_date_to }}</p>
                    <p class="col-form-value-ellipsis" v-overflow-tooltip v-if="profile.client.contract_date_from != profile.client.contract_indexation_date_from && profile.client.contract_date_to != profile.client.contract_indexation_date_to">{{ profile.client.contract_indexation_date_from }}</p>
                    <p class="col-form-value-ellipsis" v-overflow-tooltip v-if="profile.client.contract_date_from != profile.client.contract_indexation_date_from && profile.client.contract_date_to != profile.client.contract_indexation_date_to">{{ profile.client.contract_indexation_date_to }}</p>

                    <p class="col-form-value-ellipsis mt-2" v-overflow-tooltip >{{ profile.client.contract_uzk_parameters_calculation_type_name}}</p>
                    
                </b-col>
            </b-row>

            <b-row v-if="profile.person.type == 2"> 
                <b-col cols="4">       
                    <p class="col-form-label-ellipsis mt-2">{{trans('placement-contract',223)}}</p>               
                    <p class="col-form-label-ellipsis">{{trans('date-from',182)}}</p>
                    <p class="col-form-label-ellipsis">{{trans('date-to',182)}}</p>
                    
                </b-col>

                <b-col cols="8">                
                    <p class="col-form-value-ellipsis mt-2">{{ profile.client.contract_type_name == null ? '-' : profile.client.contract_type_name }}</p>                
                    <p class="col-form-value-ellipsis">{{ profile.client.contract_date_from == null ? '-' : profile.client.contract_date_from }}</p>
                    <p class="col-form-value-ellipsis">{{ profile.client.contract_date_to == null ? '-' : profile.client.contract_date_to }}</p>
                

                </b-col>
            </b-row>
        </b-card>

    </div>
</template>
<script>

import moment from "moment";
export default {
    
    props:["profile"],

    mounted(){
        
    },

    computed: {
        cProfile: {
            get() { return this.profile },
            set() { this.$emit('update:profile', false) }
        }, 
    },

    data(){
        return{

        }
    },

    methods: {
     
    }

};
</script>

