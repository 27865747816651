import { render, staticRenderFns } from "./PlacementProfileDocumentsComponent.vue?vue&type=template&id=a841d078&scoped=true&"
import script from "./PlacementProfileDocumentsComponent.vue?vue&type=script&lang=js&"
export * from "./PlacementProfileDocumentsComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a841d078",
  null
  
)

export default component.exports