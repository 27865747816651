<template>
    <b-modal ref="modal-edit" v-model="computedShow" size="m" no-close-on-backdrop :title="'Change date'">
        <ValidationObserver ref="form" >
            <b-form @submit.prevent>
                
                <!--<app-row-left-label :label="'Wilt u een nieuwe Opdrachtbevestiging naar Flexwerker?'" >
                    <app-radio-group v-model="args.person_document" :options="options" validatorRules="required" />
                </app-row-left-label>

                <app-row-left-label class="mt-2" :label="'Wilt u een nieuwe Opdrachtbevestiging naar klant sturen?'" >
                    <app-radio-group v-model="args.client_document" :options="options" validatorRules="required" />
                </app-row-left-label>  

                <app-row-left-label class="mt-2" :label="'Wilt u Model overeenkomst B opsturen ter ondertekening door Flexwerker en Klant?'" >
                    <app-radio-group v-model="args.model_b_document" :options="options" validatorRules="required" />
                </app-row-left-label>-->

                <app-row-left-label :slots="[6,6]" class="mb-2">
                    <template v-slot:col-1>
                        <div class="col-form-label app-input-label app-height-min-30">Wilt u een nieuwe Opdrachtbevestiging naar Flexwerker?</div>
                    </template>
                    <template v-slot:col-2>
                        <div class="h-100 d-flex align-items-center">     
                            <app-radio-group v-model="args.person_document" :options="options" validatorRules="required" />
                        </div>
                    </template>
                </app-row-left-label>

                <app-row-left-label :slots="[6,6]" class="mb-2">
                    <template v-slot:col-1>
                        <div class="col-form-label app-input-label app-height-min-40">Wilt u een nieuwe Opdrachtbevestiging naar klant sturen?</div>
                    </template>
                    <template v-slot:col-2>
                        <div class="h-100 d-flex align-items-center">     
                            <app-radio-group v-model="args.client_document" :options="options" validatorRules="required" />
                        </div>
                    </template>
                </app-row-left-label>

                <app-row-left-label :slots="[6,6]">
                    <template v-slot:col-1>
                        <div class="col-form-label app-input-label app-height-min-40">Wilt u Model overeenkomst B opsturen ter ondertekening door Flexwerker en Klant?</div>
                    </template>
                    <template v-slot:col-2>
                        <div class="h-100 d-flex align-items-center">     
                            <app-radio-group v-model="args.model_b_document" :options="options" validatorRules="required" />
                        </div>
                    </template>
                </app-row-left-label>

            </b-form>
        </ValidationObserver>
        
        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="confirm" :loading="loading">
                {{trans('save',175)}}
            </app-button>
        </template>
    </b-modal>
</template>

<script>
import axios from "@axios";
import moment from "moment";
import { ValidationObserver } from "vee-validate";

export default {
    components: {
        ValidationObserver
    },

    props: ["show", "result", "args", "return"],

    computed: {
        computedShow: {
            get() { return this.show },
            set() { this.$emit('update:show', false) }, 
        },
        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },
        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        },
    },

    data() {
        return {
            loading: false,
            options: [ { value: 1, text: "Ja" }, { value: 0, text: "Nee" }],
        };
    },

    watch: {

    },

    methods: {
        
        confirm() {
            this.$refs.form.validate().then((result) => {
                if (!result) {
                    return false;
                }else{

                    this.cReturn = this.cArgs;
                    this.$emit("update:show", false);
                    this.$emit("update:result", 1);    
                }
            })
        },
        
        closeModal() {
            this.$emit("update:show", false);
        },
    },
};
</script>

<style scoped>
.app-local-align{
    text-align: center;
}  

.app-local-text{
    font-weight: 600 !important
}
</style>
