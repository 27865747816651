<template>
    <b-modal ref="modal-edit" v-model="computedShow" size="m" no-close-on-backdrop :title="'Change date'">
        <ValidationObserver ref="form" >
            <b-form @submit.prevent>
                
                <app-row-left-label :label="trans('date-start',3)" >
                    <app-date v-model="cArgs.date_from" :disabledDatesRange="disabledDatesRange()" :validatorRules="'required|date-check|exclude_dates:'+ distabledDates" :validatorName="trans('date-start',3)"/>
                </app-row-left-label>

            </b-form>
        </ValidationObserver>
        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="changeDates" :loading="loading">
                {{trans('save',175)}}
            </app-button>
        </template>
    </b-modal>
</template>

<script>
import axios from "@axios";
import moment from "moment";
import { ValidationObserver } from "vee-validate";

export default {
    components: {
        ValidationObserver
    },

    props: ["show", "result", "args", "return"],

    mounted() {

        this.cArgs.periods.forEach(period => {

            this.distabledDates.push(period.date_from);

        });
    },

    computed: {
        computedShow: {
            get() { return this.show },
            set() { this.$emit('update:show', false) }, 
        },
        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },
        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        },
    },

    data() {
        return {
            loading: false,
            users_args:{
                id_cao_data_main:this.args.id_cao_data_main,
                scale_value:this.args.scale_value,
                date_from:this.args.date_from,
            },
            distabledDates: []
        };
    },

    watch: {

    },

    methods: {
        
        changeDates() {
            this.$refs.form.validate().then((result) => {
                if (!result) {
                    return false;
                }else{
                    this.loading = true;
                    axios
                        .post("planning/placements/changeDates", {
                            id_placement_data_main: this.cArgs.id_placement_data_main,
                            date_from: this.cArgs.date_from
                        })
                        .then((res) => {
                            this.cReturn =  res.data;
                            
                            this.$emit("update:show", false);
                            this.$emit("update:result", 1);
                            
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        }).finally(() => {
                            this.loading = false;
                        });
                }
            })
        },

        disabledDatesRange(){
            var range = [];
            this.cArgs.periods.forEach(period => {

                range.push('(date >= '+moment(period.date_from)+' && date <= '+ moment(period.date_from) +')');
                
            });
            range = range.join(' || ');
            return range;
        },
        
        closeModal() {
            this.$emit("update:show", false);
        },
    },
};
</script>

<style scoped>
.app-local-align{
    text-align: center;
}  

.app-local-text{
    font-weight: 600 !important
}
</style>
