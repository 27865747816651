<template>
    <div>
        <b-overlay :show="loading_list" :opacity="0.0">

            <app-view-table :isTitle="false" :loading="loading_list" :isTitleTotal="false" :items="cProfile.documents.items" :filters.sync="filters" :fields="fields" :loadingInAccordion="true">
                
                    <template v-slot:menu-items="cell">
                        <b-dropdown-item @click="openDocument(cell.data.item)">{{trans('show-document',3)}}</b-dropdown-item>
                        <b-dropdown-item @click="deleteDocument(cell.data.item)" v-if="[50].includes(cell.data.item.id_document_const_type) && (user_role < 3 || profile.can_user_edit)">{{trans('remove',175)}}</b-dropdown-item>
                    </template>

                    <template v-slot:buttons v-if="user_role < 3 || profile.can_user_edit">
                        <app-button type="add" @click="addDocument">{{trans('add-document', 175)}}</app-button>
                    </template>
            </app-view-table>

            <template #overlay>
                <div></div>
            </template>
        </b-overlay>

        <placement-profile-documents-add v-if="mAdd.show" :show.sync="mAdd.show" :args.sync="mAdd.args" :result.sync="mAdd.result" :return.sync="profile" />
    </div>
</template>

<script>
import axios from "@axios";
import AppAlerts from '@core/scripts/AppAlerts';
import PlacementProfileDocumentsAdd from './PlacementProfileDocumentsAdd.vue'

export default {
    components: { 
        PlacementProfileDocumentsAdd
    },

    props:["profile"],

    mounted() {
        this.onCreateThis();
    },

    computed: {
        cProfile: {
            get() { return this.profile },
            set() { }
        }
    },

    data() {
        
        return {
            alert_class: new AppAlerts(),
            loading_list: false,
            filters: {
                sorting: {
                        field_name: '',
                        sort_direction: 0,
                        changed: 0
                },
                filtering: {           
                    types: {type: 'list', changed: 0, values: [], function: "getCurrentDocumentTypes", parameters: {id_placement_data_main: this.profile.placement.id_placement_data_main}},
                    descriptions: {type: 'list', changed: 0, values: [], function: "getCurrentDocumentDescription", parameters: {id_placement_data_main: this.profile.placement.id_placement_data_main}}
                },
                page: 1,
                per_page: 5,
                search: "",
                changed: 0
            },

            fields: [
                { key: "document_type_name", label: this.trans('document-type',182), thStyle: { "width": "100%"},visible: true, filtering: true, filtering_name: "types" },
                { key: "description", label: this.trans('description',182), thStyle: { "min-width": "360px", "max-width": "360px" },visible: true, filtering: true, filtering_name: 'descriptions' },
                { key: "entry_date", label: this.trans('entry-date-on',182), thStyle: { "min-width": "150px", "max-width": "150px" },visible: true },
            ],

            mAdd: { show: false, args: {
                id_placement_data_main: null,
                filters: null,
            }, result: 0, return: null },
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,

        };
    },

    methods: {

        getList: function() {
            this.loading_list = true;
            axios
                .post("planning/placements/getDocumentsList", {
                    id_placement_data_main: this.profile.placement.id_placement_data_main,
                    filters: JSON.stringify(this.filters),
                })
                .then((res) => {
                    this.cProfile.documents = res.data;
                    this.filters.page = res.data.page;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_list = false;
                });
        },

        addDocument(){
            this.mAdd.args.id_placement_data_main = this.profile.placement.id_placement_data_main;
            this.mAdd.args.filters = this.filters;
            this.mAdd.show = true;
        },

        deleteDocument(data){
            var message = "";
            if(data.document_name != null){
                message = this.trans('delete-document-confirmation',177,{document_name:data.document_name})
            }else{
                message = this.trans('as-document-delete-confirmation',216)
            }
            this.alert_class.openAlertConfirmation(message).then(res => {
            if(res == true) {

                this.loading_list = true;
                
                axios
                    .post("planning/placements/removeFile", {                        
                        id_document_data_main: data.id,
                        id_placement_data_main: this.profile.placement.id_placement_data_main,
                        filters: JSON.stringify(this.filters),
                    })
                    .then((res) => {
                        this.filters.page = res.data.page;
                        this.cProfile.documents = res.data;
                    })
                    .catch((error) => {
                        console.error(error);
                    })
                    .finally(() => {
                        this.loading_list = false;
                    });
                }
            })
        },

        openDocument: function(data) {
            
            this.loading_list = true;
            axios
                .post("planning/placements/openDocument", {
                    id_document_data_main: data.id,
                    id_placement_data_main: this.profile.placement.id_placement_data_main,
                    id_document_const_type: data.id_document_const_type,
                }, 
                {
                    responseType: 'blob', 
                    crossDomain: true,
                })
                .then((res) => {

                    const url = window.URL.createObjectURL(new Blob([res.data], {type : data.mine}));
                    const link = document.createElement('a');
                    link.href = url;
                    link.target= "_blank";
                    data.mine == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                    data.mine == 'application/msword' ||
                    data.mine == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                    data.mine == 'text/plain' ? link.setAttribute('download', data.document_name):link.setAttribute('open', data.document_name); 
                    link.setAttribute('open', data.document_name); 
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((error) => {
                    console.error(error);
                    this.alert_class.openAlertWarning(this.trans('file-not-found',180)).then(res => {})
                })
                .finally(() => {
                    this.loading_list = false;
                });

        },

        
        onCreateThis() { 

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });
            this.$watch('profile.documents.items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                   this.getList();
                }   
            });

            this.$watch('filters.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.filtering.types.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.filtering.descriptions.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });
        },

    },
};
</script>

<style scoped>
</style>
