<template>
    <!-- TO DO MLK zmiana hedera modala -->
    <b-modal ref="modal-edit" v-model="computedShow" size="m" no-close-on-backdrop :title="trans('placement-change-location',213)">
            <app-row-left-label :slots="[3,6,3]">
                <template v-slot:col-1>
                    <div class="app-local-align">
                        <p class="col-form-value app-local-text">{{trans('organization',169)}}</p>
                        <p class="col-form-value app-local-text">{{cArgs.client_office_percent}}%</p>
                    </div>
                </template>
                <template v-slot:col-2>
                    <app-slider class="mt-3" v-model="cArgs.client_office_percent" min="0" max="100" step="5" validatorRules="required" validatorName="tett"/>
                </template>
                <template v-slot:col-3>
                    <div class="app-local-align">
                        <p class="col-form-value app-local-text">{{trans('flexworker',182)}}</p>
                        <p class="col-form-value app-local-text">{{100 - cArgs.client_office_percent}}%</p>
                    </div>
                </template>
            </app-row-left-label>
        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="changeDescription" :loading="loading">
                {{trans('save',175)}}
            </app-button>
        </template>
    </b-modal>
</template>

<script>
import axios from "@axios";
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

export default {
    components: {
        
    },

    props: ["show", "result", "args", "return"],

    computed: {
        computedShow: {
            get() { return this.show },
            set() { this.$emit('update:show', false) }, 
        },
        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },
        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        }
    },

    mounted(){},

    data() {
        return {
            loading: false,
            formatter1: '{value}%',
            options: {
                dotSize: 14,
                direction: 'ltr',
                min: 0,
                max: 100,
                interval: 5,
                tooltipPlacement: 'bottom',
                tooltip: 'active',
            },
            tooltip_style: {
                color: '#174066',
                background: 'transparent',
                borderColor: 'transparent',
                transform: 'translate(-50%, -4px)',
                position: 'absolute',
                fontWeight: '500'
            },
            width: 0,
            slider_refresh: 1,
            zoom: 1,
            scales: { '1.10': 0.9, '1.25': 0.8, '1.50': 0.67, '1.75': 0.55, '2.00': 0.5},
            percent: 15,
        };
    },

    methods: {
        
        changeDescription() {

                    this.loading = true;
                    axios
                        .post("planning/placements/changeOfficePercentage", {
                            id_placement_data_main: this.cArgs.id,
                            client_office_percent: this.cArgs.client_office_percent,
                        })
                        .then((res) => {
                            this.cReturn =  res.data;
                            this.cReturn.components.items_json = JSON.stringify(this.cReturn.components.items);
                            this.cReturn.calculation_concept.items_json = JSON.stringify(this.cReturn.calculation_concept.items);
                            
                            this.$emit("update:show", false);
                            this.$emit("update:result", 1);
                            
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        }).finally(() => {
                            this.loading = false;
                        });
        },
        
        closeModal() {
            this.$emit("update:show", false);
        },
    },
};
</script>

<style scoped>
.app-local-align{
    text-align: center;
}  

.app-local-text{
    font-weight: 600 !important
}




</style>
