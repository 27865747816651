<template>

    <b-card no-body class="app-card-profile"> 
        <b-row>
            <b-col start>
                <div class="app-card-profile-title mb-3">
                    {{trans('pl-parameters',213)}}
                </div>
            </b-col>              
        </b-row>
        <b-row class="app-component-icon-edit">
            <b-dropdown variant="link"  no-caret right v-if="(user_role < 3 || profile.can_user_edit) && profile.placement.id_placement_const_status == 1">
                <template #button-content>
                    <b-icon icon="three-dots-vertical" class="text-body"></b-icon>
                </template>
                <b-dropdown-item @click="changePercentage()" v-if="(user_role < 3 || profile.can_user_edit) && profile.person.id_office_data_main != profile.client.id_office_data_main">{{trans('pl-change-percentage',222)}}</b-dropdown-item>
                <b-dropdown-item @click="changeLevel()" v-if="profile.person.type == 1 && (user_role < 3 || profile.can_user_edit)">{{trans('pl-change-periodic',222)}}</b-dropdown-item>
                <b-dropdown-item @click="changeBTW()" v-if="profile.person.type == 2 && profile.placement.parameters.informations.editable_btw == 1 && (user_role < 3 || profile.can_user_edit)">BTW vrijgesteld wijzigen</b-dropdown-item>
                <b-dropdown-item @click="changeContactPerson()" v-if="user_role < 3 || profile.can_user_edit">{{trans('pl-change-contact-person',223)}}</b-dropdown-item>
            </b-dropdown>
        </b-row> 
        
        <b-row v-if="profile.person.type == 1"><!-- UZK --> 
            <b-col cols="4">
                
                <div class="mb-2">
                    <p class="col-form-label-ellipsis" v-overflow-tooltip >{{trans('organization',169)}} {{trans('pl-settlement-rate',213).toLowerCase()}}</p>
                    <p class="col-form-label-ellipsis" v-overflow-tooltip>{{trans('flexworker',182)}} {{trans('pl-settlement-rate',213).toLowerCase()}}</p>
                </div>    

                <p class="col-form-label-ellipsis" v-overflow-tooltip >{{trans('fp-periodic',184)}}</p>
                <p class="col-form-label-ellipsis" v-overflow-tooltip >{{trans('scale',182)}}</p>
                <p class="col-form-label-ellipsis" v-overflow-tooltip >{{trans('hour-wage',3)}}</p>
                
                <div class="d-flex">
                    <p class="col-form-label-ellipsis mb-2" v-overflow-tooltip >{{trans('cp-hourly-wage-base-on',192)}}</p>
                    <b-icon icon="info-circle-fill" id="popover-target-1" class="ml-2 mt-1 app-cursor-pointer" style="width: 17px; height: 17px;" />
                </div>
                <p class="col-form-label-ellipsis" v-if="profile.placement.parameters.informations.client_increase_percent != null" v-overflow-tooltip >{{trans('pl-fixed-organization-raise-rate',213)}}</p>
                <p class="col-form-label-ellipsis" v-overflow-tooltip v-if="profile.placement.parameters.informations.client_contract_base != null">{{trans('placement-basic',213)}} {{profile.client.contract_uzk_parameters_calculation_type_name.toLowerCase()}}</p>
                <p class="col-form-label-ellipsis mb-2" v-overflow-tooltip v-if="profile.placement.parameters.informations.client_contract_overhour != null">{{trans('cw-ort',194)}} {{profile.client.contract_uzk_parameters_calculation_type_name.toLowerCase()}}</p>
                <p class="col-form-label-ellipsis" v-overflow-tooltip>{{trans('contactperson', 169)}}</p>

            </b-col>

             <b-col cols="8">
                <div class="mb-2" v-if="profile.person.id_office_data_main == profile.client.id_office_data_main">
                    <p class="col-form-value" v-overflow-tooltip v-if="profile.person.id_office_data_main == profile.client.id_office_data_main" v-html="trans('placement-not-available-client',213,{office_name : profile.client.office_name, office_location : profile.client.office_location})"></p>
                    <p class="col-form-value" v-overflow-tooltip v-if="profile.person.id_office_data_main == profile.client.id_office_data_main" v-html="trans('placement-not-available-person',213,{office_name : profile.person.office_name, office_location : profile.person.office_location})"></p>
                </div>

                <div class="mb-2" v-if="profile.person.id_office_data_main != profile.client.id_office_data_main">
                    <p class="col-form-value" v-overflow-tooltip v-if="profile.person.id_office_data_main != profile.client.id_office_data_main"><b>{{ profile.placement.client_office_percent }}%</b> - {{profile.client.office_name}} - {{profile.client.office_location}}</p>
                    <p class="col-form-value" v-overflow-tooltip v-if="profile.person.id_office_data_main != profile.client.id_office_data_main"><b>{{ profile.placement.person_office_percent }}%</b> - {{profile.person.office_name}} - {{profile.person.office_location}}</p>
                </div>

                <div class="d-flex">
                       <p class="col-form-value" v-overflow-tooltip >{{profile.placement.custom_person_level ? (profile.placement.custom_person_level ? profile.placement.custom_person_level : '-') : profile.placement.parameters.informations.payroll_level ? profile.placement.parameters.informations.payroll_level : '-'}}</p>
                       <b-icon icon="info-circle-fill" class="ml-2 mt-1" style="width: 17px; height: 17px;" v-if="profile.placement.custom_person_level != null && profile.placement.custom_person_level != profile.placement.parameters.informations.payroll_level" v-overflow-tooltip id="popover-target-2" />
                </div>

                <p class="col-form-value" v-overflow-tooltip >{{profile.placement.parameters.informations.payroll_scale ? profile.placement.parameters.informations.payroll_scale : '-'}}</p>
                <p class="col-form-value" v-overflow-tooltip >{{profile.placement.parameters.informations.payroll_rate ? profile.placement.parameters.informations.payroll_rate : '-'}}</p>
                <p class="col-form-value mb-2" v-overflow-tooltip >{{profile.placement.parameters.informations.payroll_scale_type == 2 ? trans('cp-hirer-reward',192) : trans('fp-scaling-title',172)}}</p>

                <p class="col-form-value" v-if="profile.placement.parameters.informations.client_increase_percent != null" v-overflow-tooltip >{{profile.placement.parameters.informations.client_increase_percent}}%</p>
                <p class="col-form-value" v-if="profile.placement.parameters.informations.client_contract_base != null" v-overflow-tooltip >{{profile.placement.parameters.informations.client_contract_base}}</p>
                <p class="col-form-value mb-2" v-if="profile.placement.parameters.informations.client_contract_overhour != null" v-overflow-tooltip >{{profile.placement.parameters.informations.client_contract_overhour}}</p>
                
                <p class="col-form-value" v-overflow-tooltip >{{profile.placement.contact_person_name ? profile.placement.contact_person_name : '-'}}</p>
             </b-col>
        </b-row>

        <b-row v-if="profile.person.type == 2"><!-- ZZP --> 
            <b-col cols="4">
                <p class="col-form-label-ellipsis" v-overflow-tooltip >{{trans('organization',169)}} {{trans('pl-settlement-rate',213).toLowerCase()}}</p>
                <p class="col-form-label-ellipsis mb-2" v-overflow-tooltip>{{trans('flexworker',182)}} {{trans('pl-settlement-rate',213).toLowerCase()}}</p>
                <p class="col-form-label-ellipsis mb-2" v-overflow-tooltip>{{trans('pl-vat-exempt',213)}}</p>
                <p class="col-form-label-ellipsis" v-overflow-tooltip>{{trans('contactperson', 169)}}</p>

            </b-col>

             <b-col cols="8">

                <div v-if="profile.person.id_office_data_main == profile.client.id_office_data_main" class="mb-2">
                    <p class="col-form-value" v-overflow-tooltip v-if="profile.person.id_office_data_main == profile.client.id_office_data_main"><b>NVT</b> - {{profile.client.office_name}} - {{profile.client.office_location}} </p>
                    <p class="col-form-value" v-overflow-tooltip v-if="profile.person.id_office_data_main == profile.client.id_office_data_main"><b>NVT</b> - {{profile.person.office_name}} - {{profile.person.office_location}}</p>
                </div>

                <div v-if="profile.person.id_office_data_main != profile.client.id_office_data_main" class="mb-2">
                    <p class="col-form-value" v-overflow-tooltip v-if="profile.person.id_office_data_main != profile.client.id_office_data_main"><b>{{ profile.placement.client_office_percent }}%</b> - {{profile.client.office_name}} - {{profile.client.office_location}}</p>
                    <p class="col-form-value" v-overflow-tooltip v-if="profile.person.id_office_data_main != profile.client.id_office_data_main"><b>{{ profile.placement.person_office_percent }}%</b> - {{profile.person.office_name}} - {{profile.person.office_location}}</p>
                </div>

                <p class="col-form-value mb-2" v-overflow-tooltip >{{profile.placement.custom_btw_value != null ? (profile.placement.custom_btw_value == 1 ? trans('pl-subject-to-vat',213) : trans('pl-no-subject-to-vat',213)) : (profile.placement.parameters.informations.default_btw_value != null ? (profile.placement.parameters.informations.default_btw_value == 1 ? trans('pl-subject-to-vat',213) : trans('pl-no-subject-to-vat',213)) : '-')                        }}</p>
                <p class="col-form-value" v-overflow-tooltip >{{profile.placement.contact_person_name ? profile.placement.contact_person_name : '-'}}</p>

                
             </b-col>
        </b-row>

        <placement-profile-parameters-percentage-change v-if="mPlacementOfficeEdit.show" :show.sync="mPlacementOfficeEdit.show" :result.sync="mPlacementOfficeEdit.result" :args="mPlacementOfficeEdit.args" :return.sync="cProfile" />
        <placement-profile-parameters-person-level-change v-if="mPeriodicEdit.show" :show.sync="mPeriodicEdit.show" :result.sync="mPeriodicEdit.result" :args="mPeriodicEdit.args" :return.sync="cProfile" />
        <placement-profile-parameters-contact-person-change v-if="mContactEdit.show" :show.sync="mContactEdit.show" :result.sync="mContactEdit.result" :args="mContactEdit.args" :return.sync="cProfile" />
        <placement-profile-parameters-btw-change v-if="mBTWEdit.show" :show.sync="mBTWEdit.show" :result.sync="mBTWEdit.result" :args="mBTWEdit.args" :return.sync="cProfile" />

        <b-popover target="popover-target-1" triggers="focus" no-fade>
            <template #title>
                {{trans('information',182)}}
            </template>
            <p class="app-popover-text" v-html="trans('cp-hourly-wage-source-tooltip',192)"></p>
        </b-popover>
        <b-popover placement="top" target="popover-target-2" triggers="focus">{{trans('pl-custom-scale-set',213)}}</b-popover>
    </b-card>
</template>
<script>

import moment from "moment";
import PlacementProfileParametersPercentageChange from './PlacementProfileParametersPercentageChange.vue';
import PlacementProfileParametersPersonLevelChange from './PlacementProfileParametersPersonLevelChange.vue';
import PlacementProfileParametersContactPersonChange from './PlacementProfileParametersContactPersonChange.vue';
import PlacementProfileParametersBtwChange from './PlacementProfileParametersBTWChange.vue';

export default {

    components:{
        PlacementProfileParametersPercentageChange,
        PlacementProfileParametersPersonLevelChange,
        PlacementProfileParametersContactPersonChange,
        PlacementProfileParametersBtwChange
    },
    
    props:["profile"],

    computed: {
        cProfile: {
            get() { return this.profile },
            set(value) { this.$emit('update:profile', value) }
        }, 
    },

    data(){
        return{
            mPlacementOfficeEdit: { show: false, args: {
                    id: null,
                    client_office_percent: null,
                }, result: 0, return: null },
            mPeriodicEdit: { show: false, args: {
                    id_placement_data_main: null,
                    custom_person_level: null,
                    id_cao_data_main: null,
                    scale_value: null,
                    date_from: null,
                    id_person_data_main: null,
                    id_client_data_main: null,
                    id_cao_data_main: null,
                    id_function_data_main: null,
                    placement_date_from: null
                }, result: 0, return: null },
            mContactEdit: { show: false, args: {
                    id_placement_data_main: null,
                    id_client_data_main: null,
                    id_contact_data_main: null,
                    id_person_data_main: null,
                    id_cao_data_main: null,
                    id_function_data_main: null,
                    date_from: null
            }, result: 0, return: null },
            mBTWEdit:{ show: false, args: {
                                            id_placement_data_main: null,
                                            custom_btw_value: null,
                                            id_client_data_main: null,
                                            id_person_data_main: null,
                                            id_cao_data_main: null,
                                            id_function_data_main: null,
                                            date_from: null
                                           },
                        result: 0, return: null

            },

            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,

        }
    },

    methods: {

        changePercentage() {
            this.mPlacementOfficeEdit.args.id = this.cProfile.placement.id_placement_data_main;
            this.mPlacementOfficeEdit.args.client_office_percent = this.profile.placement.person_office_percent > 0 || this.profile.placement.client_office_percent > 0 ? this.profile.placement.client_office_percent : 50;

            this.mPlacementOfficeEdit.show = true;
            
        },

        changeLevel(){
            this.mPeriodicEdit.args.id_placement_data_main = this.cProfile.placement.id_placement_data_main;
            this.mPeriodicEdit.args.custom_person_level = this.profile.placement.custom_person_level != null ? {name: this.profile.placement.custom_person_level, value: this.profile.placement.custom_person_level} : {name: this.profile.placement.parameters.informations.payroll_level, value: this.profile.placement.parameters.informations.payroll_level};
            this.mPeriodicEdit.args.id_cao_data_main = this.profile.placement.id_cao_data_main;
            this.mPeriodicEdit.args.scale_value = this.profile.placement.parameters.informations.payroll_scale;
            this.mPeriodicEdit.args.date_from = this.profile.placement.placement_date_from_format;

            this.mPeriodicEdit.args.id_person_data_main = this.profile.person.id_person_data_main; 
            this.mPeriodicEdit.args.id_client_data_main = this.profile.client.id_client_data_main;
            this.mPeriodicEdit.args.id_cao_data_main = this.profile.placement.id_cao_data_main;
            this.mPeriodicEdit.args.id_function_data_main = this.profile.placement.id_function_data_main; 
            this.mPeriodicEdit.args.placement_date_from = this.profile.placement.date_from;

            this.mPeriodicEdit.show = true;
        },

        changeContactPerson(){
            this.mContactEdit.args.id_placement_data_main = this.cProfile.placement.id_placement_data_main;
            this.mContactEdit.args.id_client_data_main = this.profile.client.id_client_data_main;
            this.mContactEdit.args.id_contact_data_main = this.profile.placement.id_contact_data_main;
            this.mContactEdit.args.id_person_data_main = this.profile.person.id_person_data_main; 
            this.mContactEdit.args.id_cao_data_main = this.profile.placement.id_cao_data_main;
            this.mContactEdit.args.id_function_data_main = this.profile.placement.id_function_data_main; 
            this.mContactEdit.args.date_from = this.profile.placement.date_from;
            this.mContactEdit.show = true;
        },

        changeBTW(){
            this.mBTWEdit.args.id_placement_data_main = this.cProfile.placement.id_placement_data_main;
            this.mBTWEdit.args.default_btw_value = this.profile.placement.custom_btw_value != null ? this.profile.placement.custom_btw_value : this.profile.placement.parameters.informations.default_btw_value;
            this.mBTWEdit.args.id_client_data_main = this.profile.client.id_client_data_main;
            this.mBTWEdit.args.id_person_data_main = this.profile.person.id_person_data_main; 
            this.mBTWEdit.args.id_cao_data_main = this.profile.placement.id_cao_data_main;
            this.mBTWEdit.args.id_function_data_main = this.profile.placement.id_function_data_main; 
            this.mBTWEdit.args.date_from = this.profile.placement.date_from;
            this.mBTWEdit.show = true;
        }

    }

};
</script>

