<template>
     <div>
        <app-view-table :isTitle="false" :loading="loading_list" :filters.sync="filters" :items="cProfile.hours.items" :fields="fields" :isDropdownMenu="false" :loadingInAccordion="true" :customColumns="custom_colums" @onRowClicked="item=>$set(item, '_showDetails', !item._showDetails)">
                
            <template v-slot:row-details="row" >
                <placement-profile-hours-details-component :itemData="row.data.item" />
            </template>

            <template v-slot:pause="cell">
                {{cell.data.item.pause ? cell.data.item.pause : '-'}}
            </template>

        </app-view-table>

        <b-row align-v="center" class="mx-2" v-if="cProfile.hours.items.list.length > 0"> 
            <b-col class="p-0">
                <h4 class="pl-1 card-title mb-2 mt-2"> {{trans('placement-total-hours', 213)}} </h4>
            </b-col>
            <b-col end cols="auto" class="app-width-min-100">
                <h4 class="pl-1 card-title mb-2 mt-2" v-if="cProfile.hours.items.total_pause"> {{cProfile.hours.items.total_pause}} {{trans('sh-hour', 215).toLowerCase()}} </h4><h4 class="pl-1 card-title mb-2 mt-2" v-else>-</h4>
            </b-col>
            <b-col end cols="auto" class="app-local-width-130">
                <h4 class="pl-1 card-title mb-2 mt-2"> {{(''+cProfile.hours.items.total_hours).replace('.', ',')}} {{trans('sh-hour', 215).toLowerCase()}} </h4>
            </b-col>
        </b-row>
    </div>
</template>
<script>

import axios from "@axios";
import moment from "moment";
import PlacementProfileHoursDetailsComponent from './PlacementProfileHoursDetailsComponent.vue'

export default {
    props:["profile"],

    components: { 
        PlacementProfileHoursDetailsComponent,       
    },

    mounted() {
        this.onCreateThis();
   
    },

    computed: {
        cProfile: {
            get() { return this.profile },
            set() { }
        },
    },

    data() {
        return {
            loading_list: false,
            custom_colums: ['pause'],
            filters: {
                page: 1,
                per_page: 5,
                 sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0,

                },
                
                filtering: {},
                status: 1,
                changed: 0,
            },
            fields: [
                { key: "date_from", label: this.trans('date-from',182), thStyle: {  "width": "100%" }, visible: true, filtering: false },
                { key: "hour_from", label: this.trans('time-from',3), thStyle: { "min-width": "150px", "max-width": "150px" }, visible: true, filtering: false },
                { key: "hour_to", label: this.trans('time-to',3), thStyle: { "min-width": "150px", "max-width": "150px" }, visible: true, filtering: false},
                { key: "pause", label: this.trans('sh-pause',215), thStyle: { "min-width": "100px", "max-width": "100px" }, visible: true, filtering: false},
                { key: "total", label: this.trans('placement-total', 213) , thStyle: { "min-width": "100px", "max-width": "100px" }, visible: true, filtering: false},
            ],
            items: [],
        };
    },

    methods: {
        getList: function() {
            this.loading_list = true;

            axios
                .post("planning/placements/getHours", {
                    id_placement_data_main: this.profile.placement.id_placement_data_main,
                    filters: JSON.stringify(this.filters),
                })
                .then((res) => {
                    this.cProfile.hours.items = res.data.items;
                    this.filters.page = res.data.page;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_list = false;
                });
        },

        onCreateThis() { 

            this.getList();

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });
            this.$watch('filters.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });
            this.$watch('profile.hours.items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });

        }
    },
 
};
</script>
<style scoped>
.app-local-width-130{
    min-width: 130px !important;
}

</style>