<template>
     <div>
        <b-row class="align-items-center mb-3" v-if="profile != null">
            <b-col cols="8">
                 <b-row class="align-items-center ml-1 mr-1">
                    <b-col>
                        <div style="font-size: 19px"><span class="app-local-location">{{profile.client.name}}</span></div>
                        <div style="font-size: 14px">{{profile.placement.cao_name}} | {{profile.person.type_name}}</div>                        
                    </b-col>

                    <b-col cols="auto" end>
                        <div style="font-size: 14px;  font-weight: 600; text-align:right">
                            <span v-bind:class="{ dotActive: profile.placement.id_placement_const_status == 1 || profile.placement.id_placement_const_status == 2, dotInactive: profile.placement.id_placement_const_status == 3 || profile.placement.id_placement_const_status == 4 }"> </span> {{profile.placement.status_name}} <br>
                            <div class="d-flex align-items-center">
                                <app-select v-model="cProfile.placement.current_placement" reduceValue :clearable="false" :options="profile.periods" class="mt-1 app-width-min-220 text-left" :customLoading="loading || local_loading"/>
                                <b-icon @click="changeDates" icon="pencil-fill" class="text-body list-button-icon mt-1 ml-2 align-top app-local-pointer" v-if="user_role == 1"></b-icon>
                                <b-icon @click="deletePlacement" icon="trash-fill" class="text-body list-button-icon mt-1 ml-2 align-top app-local-pointer" v-if="user_role == 1"></b-icon></div>                         
                        </div>
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="4">
               <div class="h-100 d-flex align-items-center">
                        
                        <img class="rounded-circle custom-avatar" v-if="profile.person.avatar != null" :src="profile.person.avatar" alt="Avatar" />
                        <img class="rounded-circle custom-avatar" v-else-if="profile.person.sex == 2" src="@/assets/images/avatars/female.jpg" alt="Avatar" />
                        <img class="rounded-circle custom-avatar" v-else  src="@/assets/images/avatars/male.jpg" alt="Avatar" /> 
                        
                        <div class="align-items-center ml-3">
                            
                            <b-row>
                                <b-col>
                                    <div class="d-flex align-items-center">
                                        <h4 class="m-0 mr-2">{{profile.person.name}}</h4>
                                    </div>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col end sm="12" lg="auto">    
                                    <span class="app-local-person-data"> {{profile.placement.function_name}} </span>
                                </b-col>
                            </b-row>
                            
                        </div>
                    </div>
            </b-col>
        </b-row>
            <placement-profile-change-date v-if="mChangeDates.show" :show.sync="mChangeDates.show" :args.sync="mChangeDates.args" :result.sync="mChangeDates.result" :return.sync="cProfile"/>
    </div>
</template>
<script>

import moment from "moment";
import axios from "@axios";
import AppAlerts from '@core/scripts/AppAlerts';
import PlacementProfileChangeDate from './PlacementProfileChangeDate.vue'

export default {
    components: {
        PlacementProfileChangeDate
    },

    props:["profile", "loading"],

    mounted(){
        
    },

    computed: {
        cProfile: {
            get() { return this.profile },
            set(value) { this.$emit('update:profile', value) }
        }, 
    },

    data(){
        return{
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
            mChangeDates: { show: false, args: {
                id_placement_data_main: null,
                periods: null,
                date_from: null,
                date_to: null,

            }, result: 0, return: null },
            alert_class: new AppAlerts(),
            local_loading: false,
        }
    },

    methods: {
        changeDates(){
            this.mChangeDates.args.id_placement_data_main = this.profile.placement.id_placement_data_main;
            this.mChangeDates.args.periods = this.profile.periods.filter(period => period.value != this.profile.placement.id_placement_data_main);
            this.mChangeDates.args.date_from = moment(this.profile.placement.date_from, 'YYYY-MM-DD').format('DD-MM-YYYY');
            this.mChangeDates.args.date_to = moment(this.profile.placement.date_to, 'YYYY-MM-DD').format('DD-MM-YYYY');

            this.mChangeDates.show = true;
        },

        deletePlacement(){
            this.alert_class.openAlertConfirmation(this.trans('confirm-message', 180)).then((res) => {
                if(res == true){
                    this.local_loading = true; 
                    axios
                        .post('planning/placements/deletePlacement', {
                            id_placement_data_main: this.profile.placement.id_placement_data_main,
                            periods: JSON.stringify(this.profile.periods.filter(item => item.value != this.profile.placement.id_placement_data_main))
                        })
                        .then((res) => {
                            if(res.data != -1 && res.data != false){
                                this.cProfile = res.data;
                                this.$route.params.id_placement_data_main = res.data.placement.id_placement_data_main;
                            } else if (res === false){

                            } else {
                                this.$route.params.deleted = true;
                                this.$router.push({name: 'planning-placements'});
                            }
                        })
                        .catch((error) => {
                            console.error(`error: ${error}`);
                        })
                        .finally(() => {
                            this.local_loading = false;
                        }); 
                }
            });
        }
    }

};
</script>

<style scoped>
.dotActive {
  height: 10px;
  width: 10px;
  margin-right: 6px;
  background-color: #20dc37;
  border-radius: 50%;
  display: inline-block;
}
.dotInactive {
  height: 10px;
  width: 10px;
  margin-right: 6px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}
.custom-avatar{
    height: 52px;
    width: 52px;
}

 .app-local-select .vs__dropdown-menu {
    min-width: 200px !important;
}

.app-local-pointer{
    cursor:pointer;
}

</style>
