<template>
    <div>    
        <b-card no-body class="app-card-profile"> 
            <b-row>
                <b-col start>
                    <div class="app-card-profile-title mb-3">
                        {{trans('flexworker',182)}}
                    </div>
                </b-col>              
            </b-row>
            <b-row class="app-component-icon-edit">
                <b-dropdown variant="link"  no-caret right>
                    <template #button-content>
                        <b-icon icon="three-dots-vertical" class="text-body"></b-icon>
                    </template>
                    <b-dropdown-item :to="{ name: 'people-person-profile', params: { id_person_data_main: profile.person.id_person_data_main }}">{{trans('to-flexworker',175)}}</b-dropdown-item>
                </b-dropdown>
            </b-row> 

            <b-row v-if="profile.person.type == 1"> 
                <b-col cols="4">
                    <p class="col-form-label-ellipsis" v-overflow-tooltip v-if="profile.person.id_function_data_main != profile.placement.id_function_data_main">{{trans('function',3)}}</p>   
                    <p class="col-form-label-ellipsis" v-overflow-tooltip >{{trans('placement-contract',223)}}</p>
                    <p class="col-form-label-ellipsis" v-overflow-tooltip >{{trans('date-from',182)}}</p>
                    <p class="col-form-label-ellipsis" v-overflow-tooltip >{{trans('date-to',182)}}</p>
                    
                </b-col>

                <b-col cols="8">
                    <div class="d-flex" v-if="profile.person.id_function_data_main != profile.placement.id_function_data_main">
                        <p class="col-form-value-ellipsis" v-overflow-tooltip >{{ profile.person.id_function_data_main == null ? '-' : profile.person.function_name }}</p>
                        <b-icon icon="info-circle-fill" class="ml-2 mt-1" style="width: 17px; height: 17px;" v-overflow-tooltip  v-b-tooltip.hover.top :title="trans('pl-function-is-diffrent',211)" />
                    </div>
                    <p class="col-form-value-ellipsis" v-overflow-tooltip >{{ profile.person.contract_type_name == null ? '-' : profile.person.contract_type_name }}</p>
                    <p class="col-form-value-ellipsis" v-overflow-tooltip >{{ profile.person.contract_date_from == null ? '-' : profile.person.contract_date_from }}</p>
                    <p class="col-form-value-ellipsis" v-overflow-tooltip >{{ profile.person.contract_date_to == null ? '-' : profile.person.contract_date_to }}</p>
                
                </b-col>
            </b-row>
            <b-row v-if="profile.person.type == 2"> 
                <b-col cols="4">
                    <p class="col-form-label-ellipsis" v-overflow-tooltip >{{trans('pl-basic-hourly-wage',209)}}</p>

                    <p class="col-form-label-ellipsis mt-2" v-overflow-tooltip >{{trans('placement-contract',223)}}</p>
                    <p class="col-form-label-ellipsis" v-overflow-tooltip >{{trans('date-from',182)}}</p>
                    
                </b-col>

                <b-col cols="8">
                    <p class="col-form-value-ellipsis" v-overflow-tooltip >{{cPersonBasicHourlyWage}}</p>
                    
                    <p class="col-form-value-ellipsis mt-2" v-overflow-tooltip >{{ profile.person.contract_type_name == null ? '-' : profile.person.contract_type_name }}</p>
                    <p class="col-form-value-ellipsis" v-overflow-tooltip >{{ profile.person.contract_date_from == null ? '-' : profile.person.contract_date_from }}</p>                            
                </b-col>
            </b-row>
        </b-card>

    </div>
</template>
<script>

import moment from "moment";
export default {
    
    props:["profile"],

    mounted(){
        
    },

    computed: {
        cProfile: {
            get() { return this.profile },
            set() { this.$emit('update:profile', false) }
        }, 

        cPersonBasicHourlyWage: {
            get(){
                if(this.profile.person.type == 2 &&
                   this.profile.placement.parameters != null &&
                   this.profile.placement.parameters.informations.person_basic_hourly_wage != null &&
                   this.profile.placement.parameters.informations.person_basic_hourly_wage.length > 0 ){
                    var person_value = this.profile.placement.parameters.informations.person_basic_hourly_wage.filter(item => item.period_type == 1);
                    
                    if(person_value != null && person_value.length > 0 && person_value[0].value != null){
                        return parseFloat(person_value[0].value).toFixed(2).replace('.', ',');
                    }else{
                        return '-';
                    }
                    
                }
                else{
                    return '-';
                }
            }
        }
    },

    data(){
        return{

        }
    },

    methods: {

    }

};
</script>

