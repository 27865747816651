<template>
    <b-modal ref="modal-edit" v-model="computedShow" size="m" no-close-on-backdrop :title="trans('pl-change-placement',213)">
        <ValidationObserver ref="form" >
            <b-form @submit.prevent="onSubmit">
                
                <app-row-left-label :label="trans('date-from',182)">
                    <app-date v-model="date_from" :validatorName="trans('date-from',182)" :validatorRules="'required|date-check|exclude_dates:'+ distabledDates" :disabledDatesRange="disabledDatesRange()"/>
                </app-row-left-label>

            </b-form>
        </ValidationObserver>
        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="change" :loading="loading">
                {{trans('save',175)}}
            </app-button>
        </template>
    </b-modal>
</template>

<script>
import axios from "@axios";
import { ValidationObserver } from "vee-validate";
import moment from "moment";

export default {
    components: {
        ValidationObserver
    },

    props: ["show", "result", "args", "return"],

    mounted() {

        this.cArgs.periods.forEach(period => {

            this.distabledDates.push(period.date_from);

        });
    },

    computed: {
        computedShow: {
            get() { return this.show },
            set() { this.$emit('update:show', false) }, 
        },
        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },
        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        }
    },

    data() {
        return {
            loading: false,
            disabledDatesFromRange: null,
            date_from: this.args.date_from,
            distabledDates: []
        };
    },

    watch: {
      
    },

    methods: {
        
        change() {
            this.$refs.form.validate().then((result) => {
                if (!result) {
                    return false;
                }else{
                    this.loading = true;
                    axios
                        .post("planning/placements/change", {
                            id_placement_data_main: this.cArgs.id_placement_data_main,
                            id_person_data_main: this.cArgs.id_person_data_main,
                            id_client_data_main: this.cArgs.id_client_data_main,
                            date_from: this.date_from
                        })
                        .then((res) => {
                            this.cReturn = res.data;
                            this.cReturn.components.items_json = JSON.stringify(this.cReturn.components.items);
                            this.cReturn.calculation_concept.items_json = JSON.stringify(this.cReturn.calculation_concept.items);

                            this.$emit("update:show", false);
                            this.$emit("update:result", 1);

                            //this.$emit("update:show", false);
                            //this.loading = false;
                            //this.$router.push({ name: "planning-placements-profile", params: { id_placement_data_main: res.data } });
                            
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        }).finally(() => {
                            this.loading = false;
                        });
                }
            })
        },

        disabledDatesRange(){
            var range = [];
            this.cArgs.periods.forEach(period => {

                range.push('(date >= '+moment(period.date_from)+' && date <= '+ moment(period.date_from) +')');
                
            });
            range = range.join(' || ');
            return range;
        },
        
        closeModal() {
            this.$emit("update:show", false);
        },
    },
};
</script>

<style scoped>
.app-local-align{
    text-align: center;
}  

.app-local-text{
    font-weight: 600 !important
}
</style>
